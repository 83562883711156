<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active h-100"
  >
    <!--begin::SubIn-->
    <b-card class="h-100">
      <div class="row justify-content-between align-self-center mb-3">
        <div>
          <h3 class="text-primary mb-1">Dana Darurat</h3>
          <span class="text-muted"
            >Showing {{ parseInt(this.params.offset) + 1 }} to
            {{ parseInt(this.params.offset) + parseInt(params.limit) }} of
            {{ totalCount }} entries</span
          >
        </div>
        <div class="row no-gutters" style="gap: 8px">
          <b-form-group class="mb-0">
            <div class="input-icon">
              <input
                v-model="params.query"
                type="text"
                class="form-control"
                placeholder="Search..."
              />
              <span><i class="flaticon2-search-1 icon-md"></i></span>
            </div>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-form-select
              v-model="params.order"
              :options="optionFilter"
            ></b-form-select>
          </b-form-group>
          <b-button
            variant="primary"
            style="height: 38px"
            @click="modalStatus = 'Tambah'"
            v-b-modal.modal-loan
            >Tambah Nominal</b-button
          >
        </div>
      </div>
      <div
        class="h-100 d-flex pb-8"
        style="flex-direction: column; justify-content: space-between"
      >
        <div class="table-responsive">
          <b-table
            hover
            :items="tableData"
            :fields="fields"
            :per-page="params.limit"
            :current-page="refCurrentPage"
            :busy="isLoading"
            show-empty
          >
            <template #cell(amount)="row">
              {{ formatRupiah(row.item.amount) }}
            </template>
            <template #cell(admin_fee)="row">
              {{ formatRupiah(row.item.admin_fee) }}
            </template>
            <template #cell(action)="row">
              <b-button
                variant="success"
                class="py-1 px-2 font-weight-bold"
                id="detail"
                title="Lihat"
                @click="handleDetail(row.item)"
              >
                <i class="menu-icon flaticon-eye pr-0"></i>
              </b-button>
              <b-button
                variant="primary"
                class="py-1 px-2 ml-2 font-weight-bold"
                id="edit"
                title="Ubah"
                @click="handleEdit(row.item)"
              >
                <i class="menu-icon flaticon2-edit pr-0"></i>
              </b-button>
              <b-button
                variant="danger"
                class="py-1 px-2 ml-2 font-weight-bold"
                id="delete"
                title="Hapus"
                @click="handleDelete(row.item)"
              >
                <i class="menu-icon flaticon2-trash pr-0"></i>
              </b-button>
            </template>
            <template #table-busy>
              <div class="text-center text-muted my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </div>
        <div class="row justify-content-between">
          <div class="col-md-1">
            <b-form-group class="mb-0">
              <b-form-select
                id="per-page-select"
                v-model="params.limit"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-pagination
              v-model="refCurrentPage"
              :total-rows="totalCount"
              :per-page="params.limit"
              size="md"
              align="fill"
              class="ml-auto"
              style="padding: 0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </b-card>
    <!--end::SubIn-->
    <b-modal
      id="modal-loan"
      size="lg"
      :title="modalStatus + ' Nominal'"
      @hide="resetModal"
    >
      <form ref="form">
        <b-form-group
          id="input-group-2"
          :label="modalStatus === 'View' ? 'Nominal' : 'Masukan Nominal'"
          label-for="input-2"
        >
          <div class="input-icon">
            <input
              type="text"
              class="form-control"
              placeholder="Masukan harga disini"
              v-model="form.amount"
              @input="formatNumber('amount')"
              :readonly="modalStatus === 'View'"
            />
            <span>Rp.</span>
          </div>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          :label="modalStatus === 'View' ? 'Admin Fee' : 'Masukan Admin Fee'"
          label-for="input-2"
        >
          <div class="input-icon">
            <input
              type="text"
              class="form-control"
              placeholder="Masukan harga disini"
              v-model="form.admin_fee"
              @input="formatNumber('admin_fee')"
              :readonly="modalStatus === 'View'"
            />
            <span>Rp.</span>
          </div>
        </b-form-group>
      </form>
      <template #modal-footer>
        <div class="w-100" v-if="modalStatus !== 'View'">
          <b-button
            variant="primary"
            size="md"
            class="float-right"
            @click="handleSubmit"
          >
            Simpan
          </b-button>
          <b-button
            variant="secondary"
            size="md"
            class="float-right mr-3"
            @click="resetModal"
          >
            Close
          </b-button>
        </div>
        <div class="w-100" v-if="modalStatus === 'View'">
          <b-button
            variant="primary"
            size="md"
            class="mr-3 float-right"
            @click="resetModal"
          >
            Ok
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { formatRupiah } from "@/utils/helper";
import _ from "lodash";

export default {
  name: "ListSubmissionIn",
  data() {
    const { query, sort, order, limit, offset } = this.$route.query;
    return {
      // Table Req
      currentPage: 1,
      selected: null,
      isLoading: false,
      pageOptions: [10, 15, 100],
      optionFilter: [
        { value: "desc", text: "Terbaru" },
        { value: "asc", text: "Terlama" },
      ],
      fields: [
        {
          key: "id",
          label: "ID",
          align: "left",
          sortBy: "",
          sortByFormatted: true,
          filterByFormatted: true,
          width: 100,
          // sortable: true,
        },
        {
          key: "amount",
          label: "Nominal",
          align: "left",
          sortBy: "",
          // sortable: true,
        },
        {
          key: "admin_fee",
          label: "Admin Fee",
          align: "left",
          sortBy: "",
          // sortable: true,
        },
        {
          key: "action",
          align: "center",
          width: 100,
        },
      ],
      sortBy: "",
      sortDesc: true,
      sortDirection: "desc",
      tableData: [],
      totalCount: 0,
      params: {
        query: query ?? null,
        sort: sort ?? "created_at",
        order: order ?? "desc",
        limit: limit ?? 10,
        offset: offset ?? 0,
      },
      //-----//
      selectedList: {},
      form: {
        amount: 0,
        admin_fee: 0,
      },
      modalStatus: "",
    };
  },
  components: {},
  computed: {
    refCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.params.offset = (value - 1) * this.params.limit;
      },
    },
    paramsComp() {
      return JSON.parse(JSON.stringify(this.params));
    },
  },
  mounted() {
    this.getData();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Dana Darurat" },
    ]);
  },
  created() {},
  methods: {
    formatRupiah,
    navigateUrl() {
      const { query, sort, order, limit, offset } = this.params;
      const routeParams = { query, sort, order, limit, offset };
      if (JSON.stringify(this.$route.query) !== JSON.stringify(routeParams)) {
        this.$router.push({
          path: `/fund/list`,
          query: routeParams,
        });
      }
    },
    sortChange(params) {
      Object.entries(params).forEach((item) => {
        if (item[1]) {
          // eslint-disable-next-line
          console.log(item[0], item[1]);
        }
      });
    },
    getData: _.debounce(async function () {
      try {
        this.isLoading = true;
        this.navigateUrl();
        await this.$axios
          .get(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/loan-size`, {
            params: this.params,
          })
          .then(({ data }) => {
            this.tableData = data.list;
            this.totalCount = data.total;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    }, 500),
    resetModal() {
      this.form = {
        amount: 0,
        admin_fee: 0,
      };
      this.selectedList = {};
      this.$bvModal.hide("modal-loan");
    },
    async handleSubmit() {
      if (this.modalStatus === "View") return;
      try {
        const payload = {
          amount: parseInt(this.form.amount.replace(/\D/g, "")),
          admin_fee: parseInt(this.form.admin_fee.replace(/\D/g, "")),
        };
        if (Object.keys(this.selectedList).length)
          await this.$axios.put(
            `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/loan-size/${this.selectedList.id}`,
            payload
          );
        else
          await this.$axios.post(
            `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/loan-size`,
            payload
          );
        this.resetModal();
        this.getData();
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    formatNumber(model) {
      let value = this.form[model].replace(/\D/g, "");
      value = new Intl.NumberFormat("id-ID").format(value);
      this.form[model] = value;
    },
    setDataDetail(selectedList) {
      this.form = {
        amount: String(selectedList.amount),
        admin_fee: String(selectedList.admin_fee),
      };
      this.formatNumber("amount");
      this.formatNumber("admin_fee");
    },
    handleDetail(selectedList) {
      this.selectedList = selectedList;
      this.setDataDetail(selectedList);
      this.modalStatus = "View";
      this.$bvModal.show("modal-loan");
    },
    handleEdit(selectedList) {
      this.selectedList = selectedList;
      this.setDataDetail(selectedList);
      this.$bvModal.show("modal-loan");
      this.modalStatus = "Edit";
    },
    handleDelete(item) {
      this.$swal({
        title: "Hapus nominal ini  ?",
        showCancelButton: true,
        showDenyButton: true,
        showConfirmButton: false,
        denyButtonText: `Hapus`,
        heightAuto: false,
      }).then(async (result) => {
        if (result.isDenied) {
          await this.$axios
            .delete(
              `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/loan-size/${item.id}`
            )
            .then(() => {
              this.getData();
              this.$swal({
                text: "Berhasil menghapus data!",
                icon: "success",
                heightAuto: false,
              });
            });
        }
      });
    },
  },
  watch: {
    $route(newValue, oldValue) {
      if (newValue.path !== oldValue.path) {
        this.params = {
          query: null,
          sort: "created_at",
          order: "desc",
          limit: 10,
          offset: 0,
        };
        this.getData();
      }
    },
    paramsComp: {
      deep: true,
      handler: _.debounce(function (newValue, oldValue) {
        if (newValue.query !== oldValue.query) {
          this.params.offset = 0;
        }
        this.getData();
      }, 400),
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-status {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 4px 8px;
  color: #89909a;
  width: fit-content;
  border-radius: 8px;
  font-weight: 500;
  gap: 8px;
  i {
    color: #89909a;
  }
}
</style>
